
<template>
  <section class="login_box">
    <div class="login_left_box">
      <div style="width: 100%; height: 100%; position: absolute; ">
        <img  src="../../assets/title.png" style="width: 10%;height: 6%;margin-left: 90%;margin-top: 45%">
      </div>
      <!--      <div style=" width: 450px; position: absolute; transform: translate(69%, 50%);">-->
      <div style=" width: 40%; position: absolute; left: 10%; top: 10%;">
        <img  src="../../assets/welcome.png">
      </div>
      <img src="../../assets/background.png">

    </div>
    <div class="login_left_right">
      <div class="right_top" style="text-align: center;">
        <div style="margin-top: 54px;height: 24vh;"><img src="../../assets/logo-sd.png" alt=""></div>
        <div>
          <!--          <div class="left"> TxT Long Shadow</div>-->
          <div style="margin-left: 6vh;"><img src="../../assets/titletow.png" alt=""></div>
          <!--          <h2 style="color: black;">-->
          <!--            昇达自营公寓管理-->
          <!--          </h2>-->
        </div>
      </div>
      <div style="width: 100%;">
        <el-form label-position="left" :rules="rules" :model="formData"  ref="numberValidateForm" label-width="100px" class="demo-ruleForm">
          <el-form-item
              label="账号"
              prop="username"
              class="inputDeep"
              style="border: 0"
          >
            <!--            <i class="el-icon-user"></i>-->
            <el-input class="input_oen" v-model="formData.username" autocomplete="off">
              <i slot="prefix" style="color: #00AEFF" class="el-icon-user el-icon-search"></i>
            </el-input>
          </el-form-item>
          <el-form-item
              label="密码"
              prop="password"
              class="inputDeep"
              style="border: 0"
          >
            <!--       show-password clearable     <i class="el-icon-user"></i>-->
            <el-input  show-password   class="input_oen" v-model="formData.password" autocomplete="off">
              <i slot="prefix" style="color: #00AEFF" class="el-icon-lock el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item
              label="验证码"
              prop="pictureverification"
              class="inputDeep"
              style="border: 0"
          >
            <el-input  class="input_oen" v-model="formData.pictureverification " autocomplete="off">
              <i slot="prefix" style="color: #00AEFF" class="el-icon-set-up"></i>
              <div style="width: 112px;" slot="suffix">
                <!--                                <canvas ref="verify" width="112px" height="40px" @click="handleDraw"></canvas>-->
                <img :src="numberCodeImg" width="112px" class="imgThree" :height="windowHeight <= 740 ? '33px' : '50px'"
                     @click="handleDraw" alt="">
              </div>
            </el-input>

          </el-form-item>
          <el-form-item style="display: flex;justify-content: center;margin-left: -110px">
            <el-button type="primary" @click="submitForm('numberValidateForm')" style="width: 400px">登录</el-button>
            <!--            <el-button @click="resetForm('numberValidateForm')">重置</el-button>-->
          </el-form-item>
        </el-form>
      </div>
      <div style=" position: absolute;left: 70%; top: 90%; ">广西南宁昇达资产投资有限公司 <a  style="text-decoration:none;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" >浙ICP备18013362号-3X</a> </div>
    </div>
  </section>
</template>
<script>
import {loginApi, menuList, getHutoolVerifyCode, getSmSVerifyCode} from "@/api/login";
import {MessageSuccess, MessageWarning} from "@custom/message";
import {tokenCookies, menuCookies, submenuCookies, buttonCookies} from "@storage/cookies";
import DevicePixelRatio from "@/utils/devicePixelRatio";
export default {
  data() {
    return {
      windowHeight:0,
      numberCodeImg: null,//验证码
      formData: {
        username: null,
        password: null,
        pictureverification: null,
      },
      rules:{
        username:[
          {required: true, message: '账号不能为空'},
          // {type: 'number', message: '年龄必须为数字值'}
        ],
        password:[
          {required: true, message: '密码不能为空'},
          // {type: 'number', message: '密码必须2-18位'}
        ],
        imgCode:[
          {required: true, message: '验证码不能为空'},
          // {type: '', message: '验证码必须中华数字'}
        ]
      },

      imgCode: null,
      isSmsShow: false,
      countdown: 0,
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      windowWidth: 0,
    };
  },
  mounted() {
    this.getHutoolVerifyCode()//调动验证码
  },
  created() {
    // new DevicePixelRatio().init();
    const that = this;
    //获取窗体大小，用于动态样式
    this.getWindowSize();
    //添加窗体拉伸事件
    window.addEventListener('resize', this.getWindowSize);
    that.$nextTick(() => {
      //获取图片验证码
      that.handleDraw();
      that.isSmsShow = true;
      that.countdown = '获取验证码';
    });
  },
  //组件销毁
  beforeDestroy() {
    //移除窗体拉伸事件
    window.removeEventListener('resize', this.getWindowSize);
    this.$nextTick(() => {
      this.$refs['form'].resetFields();
    })
  },
  methods: {
    //获取窗体大小
    getWindowSize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    //生成图片验证码方法
    getHutoolVerifyCode() {
      const that = this;
      //调用生成图片验证码接口
      getHutoolVerifyCode().then(res => {
        //转换文件流
        that.numberCodeImg = URL.createObjectURL(res);
        console.log(that.numberCodeImg)
      })
    },
    handleDraw(){
      this.getHutoolVerifyCode()//调动验证码
    },
    // 提交登陆事件
    submitForm(formName) {
      const that = this;
      that.$refs[formName].validate((valid) => {
        console.log(valid)
        if (valid) {
          // try {
          //   that.$router.push("/home").catch(err => {});
          // }catch (err){
          //   console.log(err)
          // }
          // 走到这里说明已经表单全部校验通过
          // alert(valid);
          //   调用登录接口
          const loadingOptions = that.loadingOptions;
          const loading = that.$loading({...loadingOptions});
          that.phoneverification = that.numberCodeImg
          // 登录需要参数传给后台小哥哥
          const data = that.formData
          //登录接口
          loginApi(data).then(async (res) => {
            console.log(res)
            //获取权限
            let menuRes = await menuList();
            console.log(menuRes,'动态路由')
            let {info: {C, F, M, name}} = menuRes;
            //拆分权限
            C = C.map(item => {return {url: item.path, menuName: item.name}});
            M = M.map(item => {return {url: item.path, menuName: item.name}});
            F = F.map(item => {return {url: item.path, menuName: item.name}});
            //保存权限
            menuCookies.set(JSON.stringify(M));
            submenuCookies.set(JSON.stringify(C));
            buttonCookies.set(JSON.stringify(F));
            console.log(C,M,F)

            localStorage.setItem("userName", name);
            localStorage.removeItem("bbxs1");
            loading.close();
            MessageSuccess("登录成功");
            tokenCookies.set("token");
            //跳转首页
            that.$router.push("/home").catch(err => {});
          }).catch(err => {
            loading.close();
            that.handleDraw();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // }



  }
}
</script>
<style scoped lang="scss">
.login_box {
  width: 100%;
  overflow:hidden;
  height: 100%;
  display: flex;
  background-color: #724646;
}

.login_left_box {
  width: 60%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}

.login_left_right {
  width: 40%;
  height: 100%;
  background-color: #fff;

  .right_top {
    width: 100%;
    height: 40%;
    //background-color: #752c2c;
  }
}
.inputDeep{
  font-size: 14px;
  border-bottom: 1px solid #80A0B1 !important;
  //display: flex;
  //width: 30%;
}
.input_oen{
  margin-left: -100px;
}
.el-form{
  width: 60%;
  margin-left: 175px;
}

.imgThree{
  position: relative;
  left: 1px;
  top: -17px;
  border: 1px solid #7c7979;
}
//
::v-deep .el-input__inner {
  border: 0 !important;
  //border-bottom: 1px solid #80A0B1 !important;

}
::v-deep .el-form-item__label{
  margin-left: -80px;
}
::v-deep .input_oen{
  //margin-left: -45px;
  width: 130%;
}

.el-button{
  border: none;
  outline: none;
  background-color: #409eff;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  border-radius: 5px;
  transition: all ease 0.1s;
  box-shadow: 0px 5px 0px 0px #5ba8f6;
}
.el-button:active {
  transform: translateY(5px);
  box-shadow: 0px 0px 0px 0px #71b0f1;
}


body {
  background: #fff;
}

@function makelongrightshadow($color) {
  $val: 0px 0px $color;
  @for $i from 1 through 50 {
    $color: fade-out(desaturate($color, 1%), .02);
    $val: #{$val}, #{$i}px #{$i}px #{$color};
  }
  @return $val;
}

@function makelongleftshadow($color) {
  $val: 0px 0px $color;
  @for $i from 1 through 50 {
    $color: fade-out(desaturate($color, 1%), .02);
    $val: #{$val}, -#{$i}px #{$i}px #{$color};
  }
  @return $val;
}
.left {
  text-shadow: makelongleftshadow(hsla(231, 50%, 30%, 1));
  color: hsl(231, 50%, 60%);
}
</style>